import React from "react";
import { graphql, navigate } from "gatsby";
import { ContentstackImg } from "@riotgames/wwpub-components";
import ButtonText from "../buttons/ButtonText";
import imgBackground from "../../assets/images/backgroundPublisher.jpg";
import GridContainer from "../GridContainer";

import { PublisherSection, Headline, CenterContainer } from "./styles";
import { TopContainer } from "../Overview/styles";

export const fragment = graphql`
  fragment GameStudioOverviewFragment on Contentstack_convrgence_home_page {
    gameStudioOverview: game_studio_overview {
      headline
      studioLogo: studio_logo {
        url
      }
      leftCopy: left_copy
      rightCopy: right_copy
      exploreCta: explore_cta {
        title
        href
      }
    }
  }
`;

export interface PublisherData {
  headline: string;
  studioLogo: {
    url: string;
  };
  leftCopy: string;
  rightCopy: string;
  exploreCta: {
    title: string;
    href: string;
  };
}

interface Props {
  data: PublisherData;
}

export default class Publisher extends React.Component<Props> {
  render() {
    const { studioLogo, headline, leftCopy, rightCopy, exploreCta } = this.props.data;

    const leftCopyParagraphs = leftCopy.trim().split(/\n\n+/);
    const rightCopyParagraphs = rightCopy.trim().split(/\n\n+/);

    return (
      <PublisherSection style={{ backgroundImage: `url("${imgBackground}")` }}>
        <CenterContainer>
          <div className="logoContainer">
            <ContentstackImg image={{ url: studioLogo.url }} />
          </div>
          <Headline>{headline}</Headline>
          <div className="text-paragraph">
            {rightCopyParagraphs.map((p, i) => (
              <p key={i}>{p}</p>
            ))}
          </div>
        </CenterContainer>
        <div className="buttonRow">
          <ButtonText colorTheme="zing" callback={() => navigate(exploreCta.href)}>
            {exploreCta.title}
          </ButtonText>
        </div>
      </PublisherSection>
    );
  }
}
