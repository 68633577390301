import styled from "styled-components";
import { StyledForm, StyledField, StyledButton } from "../Riot/EmailForm/style";
import { FormErrorMessage, MessageWrapper } from "../Riot/EmailForm/components/Error/style";
import { colors, breakpoints, eases, spacing } from "../../styles/variables";
import { PrimaryButtonStyle, SectionVerticalPaddingTopDouble } from "../../styles/mixins";
import imgBackgroundDesktop from "../../assets/images/newsletter/backgroundNewsletter.jpg";
import imgBackgroundTablet from "../../assets/images/newsletter/imgBackgroundTablet.png";
import imgBackgroundMobile from "../../assets/images/newsletter/imgBackgroundMobile.png";

export const NewsletterSection = styled.section`
  ${SectionVerticalPaddingTopDouble};
  position: relative;
  background-image: url(${imgBackgroundDesktop});
  background-color: ${colors.black};
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${breakpoints.laptop}) {
    background-position: 40% bottom;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    background-position: center top;
    background-image: url(${imgBackgroundTablet});
  }

  @media (max-width: ${breakpoints.mobile}) {
    background-image: url(${imgBackgroundMobile});
  }
`;

export const TitleContainer = styled.div`
  grid-column: 2 / span 10;
  grid-row: 1;
  margin-bottom: 32px;

  h2 {
    font-family: "ProximaNova-Bold";
    font-size: 72px;
    line-height: 100%;
    color: ${colors.white};
    text-transform: uppercase;
    margin: 0 0 16px 0;
  }

  p {
    font-family: "ProximaNova-Bold";
    font-size: 20px;
    line-height: 130%;
    color: ${colors.white};
    text-transform: uppercase;
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-column: 2 / span 6;
    margin-bottom: 40px;

    > h2 {
      font-size: 32px;
    }

    > p {
      font-size: 24px;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / span 2;
    /* margin: 0 0 30px 0; */
    margin-bottom: 32px;

    > h2 {
      margin: 40px 0 24px 0;
    }

    > p {
      font-size: 18px;
    }
  }
`;

export const FormContainer = styled.div`
  position: relative;
  grid-column: 2 / span 10;
  grid-row: 2;

  ${StyledForm} {
    display: block;
    padding: 0;

    .post-signup {
      width: 100%;
      height: initial;
      min-width: initial;
      max-width: initial;
      min-height: initial;
      padding: 0;
      border: 0;
      background: transparent;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      font-family: inherit;
    }

    .post-signup-content {
      width: 100%;
      padding: 48px 72px;
      background: ${colors.zing};
      border: 4px solid ${colors.purpleDark};
      box-shadow: 8px 8px 0 ${colors.purpleDark};

      h1 {
        font-size: 22px;
        color: ${colors.purpleDark};
      }

      @media (max-width: ${breakpoints.tablet}) {
        padding: 28px 42px;

        h1 {
          font-size: 18px;
        }
      }
    }

    .signup {
      width: initial;
      height: initial;
      min-width: initial;
      max-width: initial;
      min-height: initial;
      padding: 0;
      border: 0;
      background: transparent;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      font-family: inherit;

      h1 {
        display: none;
      }
    }
  }

  ${StyledField} {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    flex: 2;

    label {
      color: ${colors.purpleDark};
    }

    label.floating {
      opacity: 0.6;
    }

    input[type="text"] {
      display: block;
      height: 54px;
      border: none;
      border-radius: 0;
      background-color: ${colors.white};
      font-family: "ProximaNova-Bold";
      font-size: 17px;
      letter-spacing: 0;
      color: ${colors.purpleDark};
      -webkit-text-fill-color: ${colors.purpleDark};
      -webkit-box-shadow: 0 0 0px 1000px ${colors.white} inset;

      ::placeholder {
        font-family: "ProximaNova-Bold";
        font-size: 17px;
      }

      :-webkit-autofill::first-line {
        font-family: "ProximaNova-Bold";
        font-size: 17px;
      }
    }

    input[type="text"].error {
      outline: 3px solid ${colors.pink};
      outline-offset: -5px;

      @media (max-width: ${breakpoints.tablet}) {
        margin-bottom: 10px;
      }
    }

    @media (max-width: ${breakpoints.tablet}) {
      flex: 1 0 100%;
    }
  }

  ${StyledButton} {
    ${PrimaryButtonStyle};
    margin: 0;
    border-radius: 0;

    display: inline-block;
    font-family: "ProximaNova-Black";
    font-size: 20px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    padding: 12px 32px;
    cursor: pointer;
    position: relative;
  }

  ${MessageWrapper} {
    @media (max-width: ${breakpoints.tablet}) {
      position: relative;
    }
  }

  ${FormErrorMessage} {
    padding-top: 10px;
    color: #fff;
    font: inherit;

    .img-container {
      display: none;
    }

    @media (max-width: ${breakpoints.tablet}) {
      padding: 0;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-column: 2 / span 6;

    form input {
      flex: 3;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / span 2;
  }
`;

export const BirthdayInputContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.white};
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 170%;
  padding: 0 12px;

  input {
    background-color: transparent;
    border: none;
    width: 50px;
    padding: 17px 0 17px 12px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex: 1 0 100%;
  }
`;

export const LegalContainer = styled.div`
  grid-column: 2 / span 10;
  grid-row: 1;
  align-items: left;
  display: flex;
  margin: 64px 0 0 0;

  a {
    color: ${colors.cream};
  }

  > p {
    font-family: "ProximaNova-Regular";
    font-size: 16px;
    line-height: 170%;
    color: ${colors.cream};
    margin: 0 0 0 0px;
    flex: 1;
  }

  @media (min-width: ${breakpoints.tablet}) {
    [lang="pl-pl"] &,
    [lang="ru-ru"] & {
      .legal {
        max-width: 620px;
      }
    }
  }

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: ${colors.white};
    display: block;
    border-radius: 100px;
    position: relative;
    transition: color 0.3s ease-out;
    flex: 0 1 auto;
  }

  label:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: ${colors.zing};
    border-radius: 16px;
    transition: 0.3s;
  }

  input:checked + label {
    background: ${colors.zing};
  }

  input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
    background: ${colors.peach};
  }

  label:active:after {
    width: 30px;
  }
`;
