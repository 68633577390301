import styled from "styled-components";
import { breakpoints, colors, zIndices } from "../../styles/variables";

export const HeroSection = styled.section`
  width: 100%;
  position: relative;
  background: ${colors.purpleDark};
  .backgroundImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: center center / cover no-repeat;
  }
  .backgroundGradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background: center center / cover no-repeat;
    background-image: linear-gradient(
      180deg,
      rgba(76, 35, 121, 0) 14.91%,
      rgba(76, 35, 121, 0.83) 42.13%,
      #4c2379 62.91%
    );
    z-index: ${zIndices.bgGradient};
  }
  .char {
    position: absolute;
    background: center center / contain no-repeat;
    pointer-events: none;
  }
  /* Ekko */
  .charLeft {
    --width: 580px;
    max-width: var(--width);
    width: 100%;
    left: calc(50% - (var(--width) * 1.3));
    top: 150px;
    z-index: ${zIndices.ekko};
  }
  .mobileEkko {
    display: none;
  }
  /* middle */
  .charSwoosh {
    position: absolute;
    max-width: 1188px;
    width: 70vw;
    right: calc(50% - 300px);
    top: 25vh;
    z-index: ${zIndices.swoosh};
  }
  /* Bad Ekko */
  .charRight {
    --width: 1000px;
    max-width: var(--width);
    width: 100%;
    z-index: ${zIndices.futureEkko};
    right: calc(50% - (var(--width) * 0.85));
    top: 100px;
  }
  /* left  */
  .charSwirl {
    max-width: 800px;
    width: 60vw;
    left: calc(50% - 800px);
    top: -5%;
    z-index: ${zIndices.swirl};
  }
  .centerCol {
    position: relative;
    width: 100%;
    max-width: 1440px;
    padding: 0 105px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: ${colors.white};
    z-index: 5;

    .mainLogo {
      width: 60vw;
      max-width: 470px;
      margin-top: 67px;
      margin-bottom: 40px;

      [lang="zh-tw"] & {
        max-width: 320px;
      }
    }
    .infoGroup {
      position: relative;
      text-align: center;
      padding: 1rem 0;
    }
    .available {
      font-size: 18px;
      margin-bottom: 16px;
    }

    .text-paragraph {
      text-align: center;
      margin-bottom: 24px;
      max-width: 600px;
      margin-right: auto;
      margin-left: auto;
    }

    .platforms {
      max-width: 430px;
      margin: 0 auto 16px;
      svg {
        display: inline-block;
        width: 35px;
        height: 35px;
        margin: 0 8px;
        position: relative;
      }
      span:nth-child(1),
      span:nth-child(2) {
        svg {
          width: 130px;
        }
      }
      span:nth-child(3) {
        svg {
          width: 90px;
        }
      }
      path {
        fill: ${colors.white};
        transition: fill 0.3s ease-out;
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    /* Ekko */
    .charLeft {
      --width: 290px;
      max-width: var(--width);
      left: calc(50% - (var(--width) * 1.48));
      top: 165px;
    }
    /* middle */
    .charSwoosh {
      max-width: 600px;
      width: 70vw;
      right: calc(50% - 50px);
      top: 25vh;
    }
    /* Bad Ekko */
    .charRight {
      --width: 600px;
      right: calc(50% - (var(--width) * 0.9));
      top: 120px;
    }
    /* left  */
    .charSwirl {
      max-width: 500px;
      width: 60vw;
      left: calc(50% - 500px);
      top: 75px;
    }

    .backgroundGradient {
      height: 100%;
    }

    .centerCol {
      padding: 0 70px;

      .mainLogo {
        width: 360px;
        margin-bottom: 50px;
      }

      .platforms {
        max-width: 295px;
        margin-bottom: 16px;
        svg {
          width: 30px;
          height: 30px;
          margin: 0 6px;
        }

        span:nth-child(1),
        span:nth-child(2) {
          svg {
            width: 80px;
          }
        }
        span:nth-child(3) {
          svg {
            width: 60px;
          }
        }
      }

      .text-paragraph {
        max-width: 360px;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .backgroundGradient {
      z-index: 1;
    }

    /* ekko */
    .desktopEkko {
      display: none;
    }

    .mobileEkko {
      display: block;
    }

    .charLeft {
      right: auto;
      left: calc(50% - 130px);
      top: 630px;
      width: 260px;
    }

    .charSwoosh {
      right: auto;
      left: calc(50% - 155px);
      top: 640px;
      width: 427px;
    }
    /* fekko */
    .charRight {
      width: 522px;
      right: calc(50% - 235px);
      top: 530px;
      z-index: 3;
    }
    .charSwirl {
      max-width: none;
      width: 700px;
      left: calc(50% - 270px);
      top: 430px;
    }

    .centerCol {
      padding: 0 24px;
      padding-bottom: 245px;

      .mainLogo {
        width: 70vw;
        max-width: 360px;
        margin-top: 32px;
        margin-bottom: 16px;
      }
      .available {
        font-size: 18px;
        line-height: 130%;
        margin-bottom: 8px;
      }
      .text-paragraph {
        line-height: 1.5em;
        margin-bottom: 18px;
      }
      .platforms {
        display: none;
      }
    }
  }
  @media (max-height: 700px) {
    .centerCol .platforms {
      display: none;
    }
  }

  /* the video component starts to shrink */
  @media (max-width: 540px) {
    /* frekko */
    .charRight {
      width: 522px;
      right: calc(50% - 230px);
      top: 61%;
    }
    .charSwirl {
      top: 47%;
      left: calc(50% - 280px);
    }
    /* ekko */
    .charLeft {
      top: 74%;
      width: 240px;
      left: calc(50% - 125px);
    }
    .charSwoosh {
      left: calc(50% - 170px);
      top: 73.5%;
      width: 427px;
    }
  }
`;
