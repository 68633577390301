import React from "react";
import { graphql } from "gatsby";
import GridContainer from "../GridContainer";
import ChampionsCard from "./ChampionsCard";
import championBG from "../../assets/images/backgroundChampions.jpg";
import swirlLeftBG from "../../assets/images/champion/bg-left-swirl.png";
import swirlRightBG from "../../assets/images/champion/bg-right-swirl.png";

import { ChampionSection } from "./styles";

export const fragment = graphql`
  fragment GameChampionsFragment on Contentstack_convrgence_home_page {
    gameChampions: game_champions {
      headline
      champions {
        headline
        thumbnail {
          url
        }
        universeLink: universe_link {
          href
          title
        }
      }
    }
  }
`;

export interface ChampionsData {
  headline: string;
  champions: [
    {
      headline: string;
      thumbnail: {
        url: string;
      };
      universeLink: {
        href: string;
        title: string;
      };
    },
  ];
}

interface Props {
  data: ChampionsData;
}

export default class Champions extends React.Component<Props> {
  render() {
    const { headline, champions } = this.props.data;

    return (
      <ChampionSection style={{ backgroundImage: `url("${championBG}")` }}>
        <img className="swirlLeft" src={swirlLeftBG} />
        <img className="swirlRight" src={swirlRightBG} />
        <GridContainer className="gridContainer">
          <div className="contentDiv">
            <div className="titleBox">
              <div className="text-header">{headline || "Champions"}</div>
            </div>
            <div className="champColumns">
              {champions.map((champ, id) => (
                <ChampionsCard key={champ.headline} imageSrc={champ.thumbnail.url} linkHref={champ.universeLink.href}>
                  {champ.headline}
                </ChampionsCard>
              ))}
            </div>
          </div>
        </GridContainer>
      </ChampionSection>
    );
  }
}
